@import "../../Styles/Variables.scss";

.profile {
	border-radius: 12px;

	&-head {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.right {
			display: flex;
			align-items: center;

			&-image {
				position: relative;

				button {
					position: absolute;
					bottom: 0.2rem;
					right: 0.2rem;
					border-radius: 50%;
				}
			}
		}

		.danger {
			background-color: #f9b1b1;
			border: none;
			outline: none;
			transition: 0.2s ease-in;

			&:hover {
				background-color: #8d1723;
			}

			&:focus {
				outline: 4px solid #f9b1b1;
			}
		}
	}

	@media screen and (min-width: 526px) {
		.reported-button {
			display: none;
		}
	}

	@media screen and (max-width: 645px) {
		&-head {
			.right {
				align-items: flex-start;
			}

			.right-details {
				padding-top: 1rem;
			}

			.reported-symbol {
				display: none;
			}

			.reported-button {
				display: inline-block;
			}
		}
	}

	@media screen and (max-width: 525px) {
		&-head {
			justify-content: center;

			.right {
				flex-direction: column;
				text-align: center;
				align-items: center;
				margin-left: 0;
				margin-bottom: 1rem;

				.right-image {
					margin: 1rem 0;
				}
			}
		}
	}
	@media screen and (max-width: 380px) {
		&-recommendations {
			div {
				button {
					span {
						display: none;
					}
				}
			}
		}
	}

	.testimonials {
		padding: 0;

		&__card {
			height: auto;
			text-align: center;

			img {
				width: 40px;
				height: 40px;
			}

			.name {
				text-align: center;
				font-weight: 300;
				color: $dark-primary;
			}
		}
	}
}
