$primary: #2196f3;
$dark-primary: #0977d1;
$grey2: #d8d2d2;
$grey3: #aaaaaa;
$grey4: #727272;
$white: #fff;
$black: #000;
$light-blue2: #bbdefb;
$light-blue1: #e3f2fd;
$bg: rgba($primary, 0.1);
