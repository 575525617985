@import "../../Styles/Variables.scss";

.footer {
	background-color: $primary;
	color: $grey2;
	width: 100%;

	span {
		color: white;
	}
	@media screen and (max-width: 380px) {
		span {
			display: block;
		}
	}
}
