@import "../../Styles/Variables.scss";

.form-page {
	min-height: 100vh;

	.form-container {
		border-radius: 10px;
		min-width: 450px;

		.progresss {
			span {
				width: 10px;
				height: 10px;
				background-color: $grey3;
				border-radius: 50%;
				margin: 2px;
			}

			.active {
				background-color: $primary;
			}
		}

		.confirm-identity {
			.document-type,
			.passport {
				margin-top: -1rem;
			}
		}

		@media screen and (max-width: 454px) {
			min-width: 350px;
		}

		@media screen and (max-width: 320px) {
			min-width: 300px;
		}
	}
}

.add-profile {
	width: 550px;

	.upload {
		// display: flex;
		&-picture {
			position: relative;
			margin: 0 1rem 0 0rem;
			img {
				width: 150px;
				height: 150px;
			}

			.plus {
				position: absolute;
				bottom: 0;
				margin-bottom: -19px;
				left: 40%;
				border-radius: 10px;
				padding: 0 8px;
			}
		}

		@media screen and (max-width: 460px) {
			flex-direction: column;

			&-picture {
				margin: 0;
			}

			&-side {
				padding: 1.5rem 1.5rem 0 1.5rem;

				p:first-child {
					text-align: center;
					margin-bottom: -0.03rem;
				}
			}
		}
	}
}
