@import "../../Styles/Variables.scss";

.brand {
	text-decoration: none;
	color: $black;
	font-size: 30px;
	font-weight: bold;
	font-size: sans-serif;

	&:visited {
		color: $black;
	}
}

.search {
	border: 1px $grey3 solid;
	border-radius: 15px;
	padding: 0.5rem 1rem;
	margin-left: 2rem;

	span {
		padding-right: 0.5rem;
		color: $primary;
		font-size: 20px;
	}

	input {
		border: 0;
		outline: none;
		background-color: transparent;
	}

	&:hover {
		border: 1px $primary solid;
	}
}

.links {
	text-decoration: none;
	color: $black;
	font-weight: bold;
	font-size: large;
	margin-right: 1rem;
}

.logout {
	color: rgb(233, 8, 8);
	&:hover {
		color: red;
	}
}

.notification-badge {
	position: relative;
	padding: 0rem 0.3rem 0 0;
	cursor: pointer;

	.icon {
		color: $grey3;
		font-size: 30px;
		transition: 200ms ease;
	}

	.badge {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 50%;
		background-color: $primary;
		color: $white;
		padding: 0.2rem;
		font-size: 70%;
		border: 1px $white solid;
		margin-top: 5px;
		transition: 200ms ease;
	}

	&:hover {
		.icon {
			color: $grey4;
		}

		.badge {
			background-color: $dark-primary;
		}
	}
}

img {
	border-radius: 15px;
	width: 50px;
	height: 50px;
}

.image {
	margin-left: 1rem;
}

.image {
	&:hover {
		// filter: blur(8px);
		// -webkit-filter: blur(0.5px);
		outline: 3px $primary solid;
	}
}

@media screen and (max-width: 767px) {
	.image {
		margin-left: 0px;
	}
}

@media screen and (max-width: 425px) {
	.brand {
		font-size: 25px;
	}
}

@media screen and (max-width: 320px) {
	.brand {
		font-size: 23px;
	}
}
