@import "../../Styles/Variables.scss";

.searchPeople {
	width: 500px;
	border-radius: 10px;

	@media screen and (max-width: 390px) {
		.form {
			display: flex;
			flex-direction: column;
			justify-content: center;

			button {
				margin-top: 1rem;
			}
		}
	}
}
