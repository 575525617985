@import url("https://fonts.googleapis.com/css2?family=Niramit&display=swap");
@import "./Variables.scss";

* {
	font-family: "Niramit", sans-serif;
}

$enable-negative-margins: true;

// import bootstrap sass files
@import "~bootstrap/scss/bootstrap";

html,
body {
	background-color: $bg;
	margin: 0;
}
