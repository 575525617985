@import "../../Styles/Variables.scss";

.notification {
	&--list {
		border-bottom: 2px grey solid;

		.right {
			div {
				background-color: $bg;
				svg {
					font-size: 2.5rem;
					color: $primary;
				}
			}
		}
	}
}
