@import "../../Styles/Variables.scss";

.single-case {
	background-color: $white;
	padding: 1.5rem 1rem;
	box-shadow: 0px 4px 4px rgba($black, 0.04);
	border-radius: 14px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	transition: 0.4s linear;
	height: 100%;

	.card-head {
		width: 100%;
		display: flex;

		.user-image {
			margin-right: 10px;
			img {
				width: 100px;
				height: 100px;
				border-radius: 15px;
			}
		}

		.user-details {
			white-space: nowrap;
			.name {
				color: $black;
				font-weight: bold;
				font-size: 20px;
				margin-bottom: -1px;
				margin-top: 10px;
				a {
					text-decoration: none;
				}
			}

			.role {
				color: $grey3;
			}

			.company {
				a {
					text-decoration: none;
					color: $primary;
				}

				a:visited {
					color: $primary;
				}
			}
		}
	}

	.card-bodyy {
		margin: 1rem 0 0.5rem 0;
		width: 100%;
		p {
			text-align: left;
			color: $black;
		}
	}
}
