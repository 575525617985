@import "../../Styles/Variables.scss";

.employees {
	.heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			font-size: 30px;
			font-family: sans-serif;
		}

		&-right {
			display: flex;
			align-items: center;

			// .search {
			// 	border-radius: 15px;
			// 	border: none;
			// 	padding: 0.5rem 1rem;
			// 	// margin-right: 1rem;
			// 	background-color: $white;

			// 	span {
			// 		padding-right: 0.5rem;
			// 		color: $primary;
			// 		font-size: 20px;
			// 	}

			// 	input {
			// 		border: 0;
			// 		outline: none;
			// 		background-color: transparent;
			// 	}

			// 	&:hover {
			// 		outline: 2px rgba($primary, 0.5) solid;
			// 	}
			// }

			// .employees-filter {
			// 	background-color: $white;
			// 	padding: 0.2rem;
			// 	box-shadow: 0px 4px 4px rgba($black, 0.04);
			// 	border-radius: 14px;

			// 	button {
			// 		font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
			// 		border-radius: 12px;
			// 		padding: 0.5rem 0.8rem;
			// 		color: $grey3;
			// 		background-color: transparent;
			// 		cursor: pointer;
			// 		transition: 0.2s ease-in;
			// 		border: none;

			// 		&:hover {
			// 			color: $primary;
			// 			background-color: $light-blue1;
			// 		}
			// 	}

			// 	.active {
			// 		background-color: $light-blue2;
			// 		color: $primary;
			// 		cursor: default;

			// 		&:hover {
			// 			background-color: $light-blue2;
			// 		}
			// 	}
			// }
		}
	}

	&-body {
		margin-top: 2rem;

		.pagination {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	@media screen and (max-width: 768px) {
		.heading {
			flex-direction: column;
			// p {
			// 	display: none;
			// }

			&-right {
				width: 100%;
				justify-content: space-between;

				.search {
					margin: 0;
				}
			}
		}

		&-body {
			margin-top: 1rem;
		}
	}

	@media screen and (max-width: 520px) {
		.heading {
			&-right {
				flex-direction: column-reverse;

				.search {
					width: 100%;
					padding: 0.5rem;
				}

				.employees-filter {
					margin: -10px 0 10px 0;
				}
			}
		}

		&-body {
			margin-top: 0.5rem;
		}
	}
}
