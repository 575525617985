@import "./Styles/Variables.scss";
.app {
	.app-background {
		background-image: url("./images/ship-background.png");
		background-size: cover;
		background-repeat: no-repeat;
		filter: blur(2px);
		position: relative;
		min-height: 100vh;
	}
	.app-body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: absolute;
		top: 0;
		width: 100%;
		min-height: 100vh;
	}
}

.hoverable:hover {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	transition: linear 0.2s;
}

.reportActionButtons {
	width: 150px;
	height: 100px;
	border: 1px grey solid;
	cursor: pointer;

	&:active {
		background-color: red;
		color: white;
	}

	&-selected {
		border-color: red;
		color: red;
	}
}

/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $light-blue1;
	border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $light-blue2;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $primary;
}
