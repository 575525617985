@import "../../Styles/Variables.scss";

.cases {
	.heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			font-size: 30px;
			font-family: sans-serif;
		}
	}

	.cases-list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1rem;
		margin-top: 1rem;
	}

	@media screen and (max-width: 1199px) {
		.cases-list {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media screen and (max-width: 890px) {
		.cases-list {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	@media screen and (max-width: 720px) {
		.heading {
			flex-direction: column;
		}
	}
	@media screen and (max-width: 610px) {
		.cases-list {
			grid-template-columns: 100%;
		}
	}
	@media screen and (max-width: 390px) {
		.heading {
			p {
				font-size: 25px;
			}
		}
	}
}
