.user {
	.left {
		.picture {
			img {
				width: 100px;
				height: 100px;
				border-radius: 15px;
				margin-right: 1.5rem;
			}
		}

		.name {
			p {
				&:first-child {
					font-size: 24px;
					margin: 6px 0 -9px 0;
				}

				&:nth-child(2) {
					span {
						display: block;
					}
				}
			}

			.vprofile {
				display: none;
			}
		}
	}
	@media screen and (max-width: 500px) {
		.left {
			width: 100%;
			.picture {
				img {
					width: 120px;
					height: 120px;
				}
			}

			.name {
				p {
					&:first-child {
						font-weight: 18px;
						margin: 0;
					}
				}
				.vprofile {
					display: block;
					margin-top: -10px;
				}
			}
		}

		.right {
			display: none;
		}
	}
}
