tr {
	td {
		&:first-child {
			display: flex;
			img {
				width: 40px;
				height: 40px;
				border-radius: 15px;
				margin-right: 0.5rem;
			}
			span {
				a {
					text-decoration: none;
					font-weight: bold;
				}
			}
		}
	}

	// @media screen and (max-width: 645px) {
	// 	td {
	// 		&:first-child {
	// 			img {
	// 				margin-right: 0.5rem;
	// 			}
	// 		}
	// 	}
	// }
}
