@import "../../Styles/Variables.scss";

.company {
	display: flex;
	border-radius: 12px;

	.left {
		display: flex;
		flex-direction: column;
		align-items: center;

		&-image {
			position: relative;

			button {
				position: absolute;
				bottom: 0.2rem;
				right: 0.2rem;
				border-radius: 50%;
			}
		}
	}

	.right {
		width: 100%;

		&-bottom {
			display: grid;
			grid-template-columns: repeat(3, 1fr);

			p {
				span:first-child {
					display: block;
				}
			}
		}
	}

	@media screen and (max-width: 1000px) {
		border-radius: 8px;
		.right {
			&-bottom {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	@media screen and (max-width: 767px) {
		flex-direction: column;
		.right {
			&-bottom {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	@media screen and (max-width: 475px) {
		.right {
			&-bottom {
				grid-template-columns: 100%;

				p {
					span:first-child {
						display: inline;
					}
				}
			}
		}
	}
}
